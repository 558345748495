@font-face {
  font-family: "SFProDisplay-Regular";
  src: local("SFProDisplay"),
    url(./assets/fonts/SFProDisplay/SFProDisplay-Regular.otf) format("opentype");
}

@font-face {
  font-family: "SFProDisplay-Medium";
  src: local("SFProDisplay"),
    url(./assets/fonts/SFProDisplay/SFProDisplay-Medium.otf) format("opentype");
}

@font-face {
  font-family: "SFProDisplay-Bold";
  src: local("SFProDisplay"),
    url(./assets/fonts/SFProDisplay/SFProDisplay-Bold.otf) format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
